.menu-container > a {
  margin-left: 1rem;
}

.menu-container > a:hover {
  text-decoration: underline;
}

.menu-container > a:last-of-type {
  margin-right: 1rem;
}

.content {
  width: 70vw;
  text-align: justify;
}

.mobile {
  width: 90vw;
}

.tablet {
  width: 80vw;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slideshow {
  margin: 0 auto;
  overflow: hidden;
}

.slide {
  display: inline-block;

  width: 100%;
  border-radius: 1rem;
}

.slideshowDots {
  text-align: center;
}
.slideshowDot {
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px 7px 0px;
  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #6a0dad;
}

.icon {
  font-size: xxx-large;
  cursor: pointer;
}
